<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div>
        <validation-observer ref="informationDocument">
            <b-row style="display:block;">
                <b-col cols="6">
                    <b-form-group
                        label="Title"
                        label-for="h-Title"
                        label-cols-md="2"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Title"
                            rules="required"
                        >
                            <b-form-input
                                id="h-Title"
                                type="text"
                                placeholder="Title"
                                maxlength="50"
                                v-model="title"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label="Description"
                        label-for="h-Description"
                        label-cols-md="2"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Description"
                            rules="required"
                        >
                            <b-form-textarea
                                no-resize
                                id="h-Description"
                                maxlength="500"
                                placeholder="Description"
                                v-model="discription"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="sendBtn" style="text-align:left;">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="addDocument"
                    :disabled="onlyRead || isSpinner"
                >
                Create
                </b-button>
                <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="$router.push({name: 'application-note-mgnt'})"
                      :disabled="onlyRead || isSpinner"
                  >
                  Cancel
                  </b-button>
            </div>
        </validation-observer>
        </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { required,min,max } from 'vee-validate/dist/rules'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormRadio,BFormFile,BFormSelect,BSpinner,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
extend('required',required);
extend('min',min);
extend('max',max);
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebaseTimeStamp from "firebase";
export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormRadio,
        BFormFile,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            title:"",
            discription: "",
            isSpinner: false,
            onlyRead:false,
            documentShowToStudent: false,
        }
    },
    created() {
        this.$root.$on('stopSpinner',()=>{
            this.isSpinner = false;
        });
        
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Application Note Management"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
    },
    methods: {
        addDocument(){
            try {             
                var self = this;
                self.$refs.informationDocument.validate().then((valid) => {
                    if (valid) {
                        this.isSpinner = true;
                        let obj = {
                            createdAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            title: this.title,
                            discription: this.discription,
                            status: 0,
                            isDeleted: false,
                        }
                        let doc = db.collection(dbCollections.APPLICATIONNOTES).doc();
                        obj.id = doc.id;
                        doc.set(obj).then(()=>{
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Application Note created successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',                               
                                },
                            });
                            this.$router.push({name: 'application-note-mgnt'});
                            this.isSpinner = false;
                        }).catch((error)=>{
                            this.isSpinner = false;
                            console.error(error);
                        })
                    } else {
                        this.isSpinner = false;
                    }
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        },
    },
};
</script>